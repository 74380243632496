import ReactTooltip from 'react-tooltip'

import TableHeadCheckbox from './TableHeadCheckbox'
import TableHeadAvatar from './TableHeadAvatar'
import TableHeadCell from './TableHeadCell'
import TableHeadActions from './TableHeadActions'
import TableHeadBorder from './TableHeadBorder'
import TableColumnExpand from './TableHeadExpand'
import s from './TableHead.scss'
import { CheckboxList, CustomPopper, Tooltip } from 'simple-core-ui'
import { BsPlusSquare } from 'react-icons/bs'
import { getColumnOffsets } from '../utils'

const TableHead = ({
  columns,
  avatarHeader,
  hasAvatar,
  hasActions,
  pinActions,
  actions = [],
  hasBorder,
  isEmpty,
  sortTable,
  allRowsSelected,
  selectAllRows,
  checkboxSize,
  showBulkSelectAll,
  expandable,
  alwaysShowActions,
  hasCgOutlineCheckbox,
  customizableColumns,
  pinnedColumns
}) => (
  <thead className={s.container}>
    <tr>
      {selectAllRows && (
        <TableHeadCheckbox
          isVisible={!isEmpty}
          allRowsSelected={allRowsSelected}
          selectAllRows={selectAllRows}
          checkboxSize={checkboxSize}
          showCheckbox={showBulkSelectAll}
          isCgOutline={hasCgOutlineCheckbox}
        />
      )}
      {expandable && <TableColumnExpand />}
      {hasAvatar && <TableHeadAvatar avatarHeader={avatarHeader} />}
      {columns.map(
        ({ columnKey, content, style = {}, isSortable, isDesc, required, pinned }, idx) => {
          const { defaultOffset, customColumnsOffset } = getColumnOffsets({
            pinnedColumns,
            width: style.width,
            key: columnKey,
            pinActions,
            customizableColumns
          })

          return (
            <TableHeadCell
              content={content}
              sortColumn={() => {
                if (isSortable) {
                  ReactTooltip.hide()
                  sortTable({ columnKey, isDesc: !isDesc })
                }
              }}
              style={{
                cursor: isSortable ? 'pointer' : 'default',
                ...style,
                ...(pinned ? { right: `${customColumnsOffset + defaultOffset}px` } : {})
              }}
              isSortable={isSortable}
              columnKey={columnKey}
              isDesc={isDesc}
              required={required}
              key={idx}
              pinned={pinned}
            />
          )
        }
      )}
      {(hasActions || (Array.isArray(actions) && actions.length > 0)) && (
        <TableHeadActions
          alwaysShowActions={alwaysShowActions}
          pinActions={pinActions}
          style={customizableColumns && pinActions ? { right: '52px' } : null}
        />
      )}
      {customizableColumns && (
        <th className={s.customizeColumns}>
          <CustomPopper
            component={
              <Tooltip
                trigger={<BsPlusSquare className={s.addColumnIcon} />}
                content="Add Columns"
                placement="top"
              />
            }
          >
            <CheckboxList
              value={customizableColumns.visibleColumns ?? columns}
              onConfirm={customizableColumns.changeSelectedColumns}
              sortSelection={customizableColumns.sortSelection}
              options={customizableColumns.allColumns}
              serialiser={data => data}
              secondaryAction={{
                element: (
                  <a
                    style={{
                      marginLeft: 10
                    }}
                    className="makeLink"
                  >
                    Restore Default
                  </a>
                ),
                onClick: customizableColumns.restoreDefaultColumns
              }}
              searchPlaceholder="Search column names"
            />
          </CustomPopper>
        </th>
      )}
      {hasBorder && <TableHeadBorder />}
    </tr>
  </thead>
)

export default TableHead
