import React, { KeyboardEvent, useState } from 'react'
import { useChatContext } from '../context/ChatContext'
import s from '../styles/AIChat.scss'
import AutoGrowTextarea from './AutoGrowTextarea'

interface MessageInputProps {
  isLoading: boolean
  onSendMessage: (text: string) => void
}

const MessageInput: React.FC<MessageInputProps> = ({ onSendMessage, isLoading = false }) => {
  const {
    data: { urls }
  } = useChatContext()

  const [message, setMessage] = useState<string>('')
  const isDisabled = isLoading || !message?.trim()

  const handleEnterSend = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    e.preventDefault()
    onSendMessage(message)
    setMessage('')
  }

  const handleClickSend = () => {
    onSendMessage(message)
    setMessage('')
  }

  const handleKeydown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (isDisabled || e.shiftKey) {
      return
    }

    if (e.key === 'Enter') {
      handleEnterSend(e)
    }
  }

  return (
    <section className={s['message-input-container']}>
      <p>
        Ask your question here (
        <a href={urls.examplePrompts}>
          <i>View Examples</i>
        </a>
        )
      </p>
      <div className={s['message-input']} id="ask-ai-input">
        <AutoGrowTextarea
          value={message}
          onChange={setMessage}
          placeholder=""
          onKeyDown={handleKeydown}
        />
      </div>
      <div className={s['message-input-footer']}>
        <span>
          AskAI may display inaccurate info so double-check its responses. Data updated once every
          24 hours.
        </span>
        <button disabled={isDisabled} className={s['submit-button']} onClick={handleClickSend}>
          Submit
        </button>
      </div>
    </section>
  )
}

export default MessageInput
