import { Markdown } from 'simple-core-ui'

export interface ChatLogEntryDTO {
  role: string
  content?: string | null
  tool_calls?: ToolCall[]
}

interface ToolCall {
  id: string
  type: string
  function: {
    name: string
    arguments: string
  }
}

function ChatLogEntry({ entry }: { entry: ChatLogEntryDTO }) {
  return (
    <div
      style={{
        border: '1px solid #ddd',
        borderRadius: '8px',
        padding: '12px',
        marginBottom: '10px',
        backgroundColor: '#f9f9f9',
        wordWrap: 'break-word',
        overflowWrap: 'break-word'
      }}
    >
      <p>
        <strong>Role:</strong> {entry.role}
      </p>
      {entry.content &&
        (entry.role === 'tool' ? (
          <pre
            style={{
              whiteSpace: 'pre-wrap',
              wordBreak: 'break-word',
              overflow: 'auto',
              maxWidth: '100%',
              backgroundColor: '#eee',
              padding: '6px',
              borderRadius: '4px'
            }}
          >
            <strong>Output:</strong>
            {(() => {
              try {
                // Most of the time, the output is a json object.
                const formattedOutput = JSON.stringify(JSON.parse(entry.content), null, 2)
                return <code>{formattedOutput}</code>
              } catch {
                return <code>{entry.content}</code>
              }
            })()}
          </pre>
        ) : (
          <div>
            <strong>Content:</strong>
            <Markdown value={entry.content} />
          </div>
        ))}
      {entry.tool_calls && entry.tool_calls.length > 0 && (
        <div style={{ marginTop: '10px' }}>
          <strong>Tool Calls:</strong>
          {entry.tool_calls.map((tool: ToolCall, idx: number) => (
            <div
              key={idx}
              style={{
                border: '1px solid #ccc',
                borderRadius: '6px',
                padding: '8px',
                marginTop: '6px',
                backgroundColor: '#fff'
              }}
            >
              <p>
                <strong>Function Name:</strong> {tool.function.name}
              </p>
              <pre
                style={{
                  whiteSpace: 'pre-wrap',
                  wordBreak: 'break-word',
                  overflow: 'auto',
                  maxWidth: '100%',
                  backgroundColor: '#eee',
                  padding: '6px',
                  borderRadius: '4px'
                }}
              >
                <strong>Arguments:</strong>
                {(() => {
                  try {
                    const formattedArgs = JSON.stringify(
                      JSON.parse(tool.function.arguments),
                      null,
                      2
                    )
                    return <code>{formattedArgs}</code>
                  } catch {
                    return <code>{tool.function.arguments}</code>
                  }
                })()}
              </pre>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default ChatLogEntry
