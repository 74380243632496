import { format } from 'date-fns-tz'
import React, { useMemo } from 'react'
import Chart from 'react-apexcharts'
import { HiSparkles } from 'react-icons/hi2'
import { Avatar, Markdown } from 'simple-core-ui'
import s from '../styles/AIChat.scss'
import { GraphDefinition, IMessage } from '../types/models'
import { buildChartOptions } from '../utils'
import WarningIcon from './WarningIcon'

const RenderGraph = React.memo(({ graph_definition }: { graph_definition?: GraphDefinition }) => {
  const apexOptions = useMemo(
    () =>
      graph_definition ? buildChartOptions(graph_definition.type, graph_definition.options) : null,
    [graph_definition]
  )

  if (!graph_definition) return null

  return (
    <Chart
      options={apexOptions!}
      series={graph_definition.series}
      type={graph_definition.type}
      height={400}
    />
  )
})

const RenderMessageBody = React.memo(
  ({
    message_type,
    text,
    graph_definition
  }: Pick<IMessage, 'message_type' | 'text' | 'graph_definition'>) => {
    if (message_type === 'loading') {
      return <div className={s['blinking-dot']}></div>
    }

    if (message_type === 'error') {
      return (
        <div className={s['error']}>
          <WarningIcon /> {text}
        </div>
      )
    }

    return (
      <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
        <RenderGraph graph_definition={graph_definition} />
        <Markdown value={text} openLinksInNewTab={true} />
      </div>
    )
  }
)

interface MessageAvatarProps {
  role: string
  user: { firstName: string; lastName: string }
}

const MessageAvatar: React.FC<MessageAvatarProps> = React.memo(({ role, user }) => {
  if (role === 'user') {
    return (
      <Avatar
        className={s['avatar-list']}
        styles={{ marginLeft: 0, background: '#3C99FE', color: '#fff' }}
        initials={`${user.firstName} ${user.lastName}`}
      />
    )
  }

  if (['assistant', 'system'].includes(role)) {
    return (
      <div className={s['avatar-list']}>
        <HiSparkles style={{ width: '25px', height: '25px', color: '#7C248E' }} />
      </div>
    )
  }

  return null
})

const Message: React.FC<{
  message: IMessage
  indexInChat?: number
  user?: { firstName: string; lastName: string }
}> = React.memo(({ message, indexInChat = 0, user }) => {
  const { role, text, created_date, message_type, graph_definition } = message
  const classNames = [s['message'], role && s[role]].filter(Boolean).join(' ')
  const formattedDate = format(
    created_date ? new Date(created_date) : new Date(),
    "MMMM d, yyyy 'at' h:mm a"
  )

  return (
    <div className={classNames} data-message-index={indexInChat}>
      <div className={s['message-bubble']}>
        <MessageAvatar role={role} user={user!} />

        <div>
          <div className={s['message-date']}>{formattedDate}</div>
          <RenderMessageBody
            message_type={message_type}
            text={text}
            graph_definition={graph_definition}
          />
        </div>
      </div>
    </div>
  )
})

export default Message
