import {
  offset,
  flip,
  shift,
  useHover,
  useFocus,
  useDismiss,
  useRole,
  useInteractions,
  useTransitionStyles,
  FloatingArrow,
  FloatingPortal,
  arrow,
  Placement,
  useClick
} from '@floating-ui/react'
import { ReactNode, useRef } from 'react'
import { IoIosInformationCircleOutline } from 'react-icons/io'
import cn, { Argument } from 'classnames'
import { useWithPopper } from 'simple-core-ui'
import s from './Tooltip.scss'

type OffsetOptions = Parameters<typeof offset>[0]

type OptionalProps = Partial<{
  trigger: ReactNode
  triggerClassName: Argument
  contentClassName: Argument
  placement: Placement
  testId: string
  hasArrow: boolean
  offset: OffsetOptions
  mode: 'hover' | 'click'
  border: boolean
  arrowStrokeColor: string
}>

type RequiredProps = {
  content: ReactNode
}

type Props = RequiredProps & OptionalProps

const Tooltip = ({
  trigger,
  triggerClassName,
  content,
  contentClassName,
  placement = 'top',
  testId,
  hasArrow = true,
  offset: offsetValue = 10,
  mode = 'hover',
  border,
  arrowStrokeColor = '#1d1d1d'
}: Props) => {
  const arrowRef = useRef(null)

  const middleware = [offset(offsetValue), flip(), shift()]
  if (hasArrow) {
    middleware.push(
      arrow({
        element: arrowRef
      })
    )
  }

  const { floatingStyles, setReference, setFloating, context } = useWithPopper({
    middleware,
    placement,
    handleState: true
  })

  const hover = useHover(context, {
    move: false,
    enabled: mode === 'hover'
  })
  const focus = useFocus(context, {
    enabled: mode === 'hover'
  })
  const click = useClick(context, {
    enabled: mode === 'click'
  })
  const dismiss = useDismiss(context)
  const role = useRole(context, { role: 'tooltip' })

  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
    focus,
    click,
    dismiss,
    role
  ])

  const transition = useTransitionStyles(context, {
    initial: { opacity: 0 },
    open: { opacity: 1 }
  })

  return (
    <>
      <span
        data-testid={testId}
        ref={setReference}
        {...getReferenceProps({ className: cn(s.trigger, triggerClassName) })}
      >
        {trigger || <IoIosInformationCircleOutline />}
      </span>
      {content && (
        <FloatingPortal>
          {transition.isMounted && (
            <div
              ref={setFloating}
              style={{
                ...transition.styles,
                ...floatingStyles
              }}
              {...getFloatingProps({
                className: cn(
                  s.content,
                  {
                    [s.text]: typeof content === 'string',
                    [s.border]: border
                  },
                  contentClassName
                )
              })}
            >
              {hasArrow && (
                <FloatingArrow
                  ref={arrowRef}
                  context={context}
                  fill="#FFF"
                  stroke={arrowStrokeColor}
                  strokeWidth={border ? 1 : 0}
                />
              )}
              {content}
            </div>
          )}
        </FloatingPortal>
      )}
    </>
  )
}

export default Tooltip
